<template>
  <section class="container970" v-loading="loading">
    <el-card>
      <div class="clubTop">
        <h3>Contact Our Club 100 Members</h3>
        <el-image :src="backgroundImage"></el-image>
      </div>
      <section class="club_pc">
        <div class="clubP" v-if="Platinum.length">
          <p>PLATINUM</p>
          <div class="bordeColor">
            <el-table :data="Platinum" @row-click="
                row =>
                  this.$router.push({
                    path: '/profile',
                    query: { user_id: row.id }
                  })
              " :show-header="false" style="width: 100%;cursor: pointer;">
              <el-table-column prop="company_name" show-overflow-tooltip min-width="100">
              </el-table-column>
              <el-table-column prop="real_name" show-overflow-tooltip min-width="150">
              </el-table-column>
              <el-table-column prop="position" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.position || "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="darkCol" prop="email" show-overflow-tooltip min-width="150">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="clubG" v-if="Gold.length">
          <p>GOLD</p>
          <div class="bordeColor">
            <el-table :data="Gold" @row-click="
                row =>
                  this.$router.push({
                    path: '/profile',
                    query: { user_id: row.id }
                  })
              " :show-header="false" style="width: 100%;cursor: pointer;">
              <el-table-column prop="company_name" show-overflow-tooltip min-width="100">
              </el-table-column>
              <el-table-column prop="real_name" show-overflow-tooltip min-width="150">
              </el-table-column>
              <el-table-column prop="position" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.position || "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="darkCol" show-overflow-tooltip prop="email" min-width="150">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="clubS" v-if="Silver.length">
          <p>SILVER</p>
          <div class="bordeColor">
            <el-table :data="Silver" @row-click="
                row =>
                  this.$router.push({
                    path: '/profile',
                    query: { user_id: row.id }
                  })
              " :show-header="false" style="width: 100%;cursor: pointer;">
              <el-table-column prop="company_name" show-overflow-tooltip min-width="100">
              </el-table-column>
              <el-table-column prop="real_name" show-overflow-tooltip min-width="150">
              </el-table-column>
              <el-table-column prop="position" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.position || "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="darkCol" show-overflow-tooltip prop="email" min-width="150">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="clubB" v-if="Bronze.length">
          <p>BRONZE</p>
          <div class="bordeColor">
            <el-table :data="Bronze" @row-click="
                row =>
                  this.$router.push({
                    path: '/profile',
                    query: { user_id: row.id }
                  })
              " :show-header="false" style="width: 100%;cursor: pointer;">
              <el-table-column prop="company_name" show-overflow-tooltip min-width="100">
              </el-table-column>
              <el-table-column prop="real_name" show-overflow-tooltip min-width="150">
              </el-table-column>
              <el-table-column prop="position" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.position || "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="darkCol" show-overflow-tooltip prop="email" min-width="150">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </section>
      <section class="club_mobile">
        <div class="clubP" v-if="Platinum.length">
          <p>PLATINUM</p>
          <div class="bordeColor">
            <el-table :data="Platinum" @row-click="
                row =>
                  this.$router.push({
                    path: '/profile',
                    query: { user_id: row.id }
                  })
              " :show-header="false" style="cursor: pointer;" fit>
              <el-table-column prop="company_name">
              </el-table-column>
              <el-table-column prop="real_name">
              </el-table-column>
              <el-table-column prop="position">
                <template slot-scope="scope">
                  <span>{{ scope.row.position || "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="darkCol" prop="email">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="clubG" v-if="Gold.length">
          <p>GOLD</p>
          <div class="bordeColor">
            <el-table :data="Gold" @row-click="
                row =>
                  this.$router.push({
                    path: '/profile',
                    query: { user_id: row.id }
                  })
              " :show-header="false" style="width: 100%;cursor: pointer;" fit>
              <el-table-column prop="company_name">
              </el-table-column>
              <el-table-column prop="real_name">
              </el-table-column>
              <el-table-column prop="position">
                <template slot-scope="scope">
                  <span>{{ scope.row.position || "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="darkCol" prop="email">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="clubS" v-if="Silver.length">
          <p>SILVER</p>
          <div class="bordeColor">
            <el-table :data="Silver" @row-click="
                row =>
                  this.$router.push({
                    path: '/profile',
                    query: { user_id: row.id }
                  })
              " :show-header="false" style="width: 100%;cursor: pointer;" fit>
              <el-table-column prop="company_name">
              </el-table-column>
              <el-table-column prop="real_name">
              </el-table-column>
              <el-table-column prop="position">
                <template slot-scope="scope">
                  <span>{{ scope.row.position || "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="darkCol" prop="email">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="clubB" v-if="Bronze.length">
          <p>BRONZE</p>
          <div class="bordeColor">
            <el-table :data="Bronze" @row-click="
                row =>
                  this.$router.push({
                    path: '/profile',
                    query: { user_id: row.id }
                  })
              " :show-header="false" style="width: 100%;cursor: pointer;" fit>
              <el-table-column prop="company_name">
              </el-table-column>
              <el-table-column prop="real_name">
              </el-table-column>
              <el-table-column prop="position">
                <template slot-scope="scope">
                  <span>{{ scope.row.position || "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column class-name="darkCol" prop="email">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </section>
    </el-card>
  </section>
</template>

<script>
export default {
  data () {
    return {
      backgroundImage: require('@/assets/images/group/club_1.png'),
      Platinum: [],
      Gold: [],
      Silver: [],
      Bronze: [],
      loading: false
    }
  },
  components: {},

  created () {
    this.getClub100Contact()
  },

  methods: {
    async getClub100Contact () {
      this.loading = true
      let res = await this.$http.getClub100Contact({})
      if (res.status == 200) {
        this.Platinum = res.data.Platinum
        this.Gold = res.data.Gold
        this.Silver = res.data.Silver
        this.Bronze = res.data.Bronze
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }

  }
}
</script>

<style lang="scss" scoped>
  @media(max-width: 768px) {
    .club_pc {
      display: none;
    }

    .clubTop {
      h3 {
        font-size: 1rem !important;
      }

      .el-image {
        height: 2.875rem !important;
      }
    }

    /deep/ .el-table th,
    .el-table td {
      white-space: nowrap;
    }

    /deep/ .el-table .cell {
      display: inline-block;
      white-space: nowrap;
      width: auto;
    }

    /deep/ .el-table .el-table__body-wrapper {
      overflow-x: auto;
    }

    /deep/ .el-table__body,
    .el-table__footer,
    .el-table__header {
      table-layout: auto;
    }
  }

  @media not screen and (max-width: 768px) {
    .club_mobile {
      display: none;
    }
  }

  .clubTop {
    display: flex;
    justify-content: space-between;

    h3 {
      color: #50b5ff;
      font-size: 40px;
    }

    .el-image {
      width: 280px;
    }
  }

  .clubP,
  .clubG,
  .clubS,
  .clubB {
    p {
      height: 20px;
      font-size: 16px;
      margin: 30px 0 0;
      font-weight: 500;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }

    .bordeColor {
      padding: 2px;
      border-radius: 5px;

      .el-table {
        color: #666;
        font-weight: 300;
      }

      ::v-deep .darkCol {
        color: #333;
        font-weight: 500;
      }
    }
  }

  .clubP {
    p {
      width: 80px;
      background-image: linear-gradient(120deg, #E2E9EF, #E0E8EF);
    }

    .bordeColor {
      background-image: linear-gradient(120deg, #dae4ec, #f4f7f8 50%, #dae4ec);
    }
  }

  .clubG {
    p {
      width: 50px;
      background-image: linear-gradient(150deg, #fbe67b, #fbe67b);
    }

    .bordeColor {
      background-image: linear-gradient(120deg, #fbe67b, #fdfae1 50%, #fbe67b);
    }
  }

  .clubS {
    p {
      width: 55px;
      background-image: linear-gradient(150deg, #bfbfbd, #bfbfbd);
    }

    .bordeColor {
      background-image: linear-gradient(120deg, #bfbfbd, #f1f1f0 50%, #bfbfbd);
    }
  }

  .clubB {
    p {
      width: 65px;
      background-image: linear-gradient(150deg, #e77c34, #fac781);
    }

    .bordeColor {
      background-image: linear-gradient(170deg,
          #f2a661,
          #fcf4e6 35%,
          #f5af65 50%,
          #fcf4e6 75%,
          #f2a661);
    }
  }
</style>
